<template>
  <b-row>
    <b-col md="12">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                <b-overlay :show="loading">
                    <template>
                        <div class="bg-primary">
                            <h5 class="text-white text-center"> {{ $t('globalTrans.basic_information') }}</h5>
                        </div>
                    </template>
                    <b-row class="mt-2">
                        <b-col lg="12" sm="12">
                            <ValidationProvider name="Circular Title" vid="circular_name" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="12"
                                    label-for="circular_name"
                                >
                                <template v-slot:label>
                                    {{ $t('exportTrophyCircular.circular') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="application.circular_name"
                                    disabled
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6" md="6" sm="6">
                            <b-row>
                                <b-col lg="12" md="12" sm="12">
                                    <ValidationProvider name="Photo" vid="photo" v-slot="{ errors }" :rules="!$route.query.id ? 'required': ''">
                                        <b-form-group
                                            class="mb-0"
                                            label-cols-sm="5"
                                            label-for="photo"
                                        >
                                            <template v-slot:label>
                                                {{$t('globalTrans.photo')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-file
                                                v-on:change="handlePhoto"
                                                accept="image/jpeg, image/jpg, image/png, image/gif"
                                                v-model="photo_input"
                                                plain
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-file>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            <small class="text-danger">{{ $t('externalUser.max_size_100') }}</small>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="12" md="12" sm="12" >
                                    <b-form-group
                                        label-for="photo"
                                        label-cols-sm="5"
                                    >
                                        <template v-slot:label>
                                        </template>
                                        <img v-if="application.photo && !application.photo_file" class="img-thumbnail" style="width: 120px;height: 120px;" :src="baseUrl + 'download-attachment?file=storage' + application.photo" alt="">
                                        <img v-else class="img-thumbnail" style="width: 120px; height: 120px;" :src="application.photo_file ? application.photo_file : application.preview" alt="">
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Export Product Sector" vid="product_service_sector_id" rules="required|min_value:1" v-slot="{ errors }">
                                <b-form-group
                                    label-for="product_service_sector_id"
                                    label-cols-sm="5"
                                >
                                    <template v-slot:label>
                                        {{$t('externalUser.export_product_sector')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                        plain
                                        v-model="application.product_service_sector_id"
                                        :options="exportSectorList"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                        <template v-slot:first>
                                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Latent Exporter" vid="latent_exporter" rules="required|min:1">
                                <b-form-group
                                    label-for="latent_exporter"
                                    slot-scope="{ valid, errors }"
                                    label-cols-sm="5"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.latent_exporter') }} <span class="text-danger">*</span>
                                </template>
                                    <b-form-radio-group
                                    class="text-left"
                                    v-model="application.latent_exporter"
                                    :options="latentExporterList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-radio-group>
                                    <div class="invalid-feedback d-block">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                                <ValidationProvider name="Investment Typee" vid="investment_type"  v-slot="{ errors }">
                                    <b-form-group
                                    label-for="investment_type"
                                    label-cols-sm="5"
                                    >
                                    <template v-slot:label>
                                    {{$t('externalUser.investment_type')}}
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="application.investment_type"
                                    :options="investmentTypeList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.org_info') }}</h5>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider name="Organization/Institution Name(En)" vid="org_name_en" rules="required" v-slot="{ errors }">
                              <b-form-group
                                  label-cols-sm="5"
                                  label-for="org_name_en"
                              >
                              <template v-slot:label>
                                  {{ $t('externalUser.org_name_en') }} <span class="text-danger">*</span> <br/>
                                  <b> {{ $t('externalUser.capital_letter') }} </b>
                                  </template>
                                  <b-form-input
                                      v-model="application.org_info.org_name_en"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider name="Organization/Institution Name(Bn)" vid="org_name_bn" rules="required" v-slot="{ errors }">
                              <b-form-group
                                  label-cols-sm="5"
                                  label-for="org_name_bn"
                              >
                              <template v-slot:label>
                                  {{ $t('externalUser.org_name_bn') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      v-model="application.org_info.org_name_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider name="Bin No" vid="bin_no" rules="required" v-slot="{ errors }">
                              <b-form-group
                                  label-cols-sm="5"
                                  label-for="bin_no"
                              >
                              <template v-slot:label>
                                  {{ $t('externalUser.bin_no') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      v-model="application.org_info.bin_no"
                                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider name="Organization/Institution Type" vid="org_type" rules="required|min_value:1" v-slot="{ errors }">
                              <b-form-group
                              label-for="org_type"
                              label-cols-sm="5"
                              >
                                <template v-slot:label>
                                  {{$t('externalUser.org_type')}} <span class="text-danger">*</span>
                                </template>
                              <b-form-select
                                plain
                                v-model="application.org_info.org_type"
                                :options="orgTypeList"
                                :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider name="Legal Form" vid="legal_form" rules="required" v-slot="{ errors }">
                              <b-form-group
                                  label-cols-sm="5"
                                  label-for="legal_form"
                              >
                              <template v-slot:label>
                                  {{ $t('externalUser.legal_form') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      v-model="application.org_info.legal_form"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider vid="epz_affiliated_org" rules="required|min_value:1" v-slot="{ errors }">
                              <b-form-group
                              label-for="epz_affiliated_org"
                              label-cols-sm="5"
                              >
                              <b-form-checkbox
                                v-model="application.org_info.epz_affiliated_org"
                                name="epz_affiliated_org"
                                value="1"
                                unchecked-value="2"
                              >
                               {{ $t('externalUser.epz_affiliated_org') }}
                              </b-form-checkbox>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <!-- <b-col xl="6" lg="6" sm="12">
                          <ValidationProvider vid="bangladeshi_involved_compnay" rules="required|min_value:1" v-slot="{ errors }">
                              <b-form-group
                              label-for="bangladeshi_involved_compnay"
                              label-cols-sm="5"
                              >
                              <b-form-checkbox
                                v-model="application.org_info.bangladeshi_owned_company"
                                name="bangladeshi_involved_compnay"
                                value="1"
                                unchecked-value="2"
                              >
                                {{ $t('externalUser.bangladeshi_involved_compnay') }}
                              </b-form-checkbox>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col> -->
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.org_tax_info') }}</h5>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="TIN No." vid="tin_no" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="tin_no"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.tin_no') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.org_tax_info.tin_no"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="TAX Circle" vid="tax_circle" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="tax_circle"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.tax_circle') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.org_tax_info.tax_circle"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Tax Region (En)" vid="tax_region_en" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="tax_region_en"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.tax_region_en') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.org_tax_info.tax_region_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Tax Region (Bn)" vid="tax_region_bn" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="tax_region_bn"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.tax_region_bn') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.org_tax_info.tax_region_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.org_address') }}</h5>
                        </div>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col v-if="isShow" lg="12" md="12" sm="12">
                            <Address :data="application.org_address" :addressType="'address'" :param="fieldObj" @address-data="receiveAddress" title="Organization/Institution Address"/>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h6 class="text-white text-center py-1"> {{ $t('externalUser.additional_business_address') }}</h6>
                        </div>
                    </b-row>
                    <b-row class="mt-2">
                        <b-col v-if="isShow" lg="12" md="12" sm="12">
                            <AdditionalAddress :data="application.additional_org_address" :addressType="'address'" :param="fieldObj" @address-data="additionalReceiveAddress" title="Additional Business Address"/>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.office_contact_info') }}</h5>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Telephone No." vid="office_contact_info.telephone_no" rules="required|max:12" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="telephone_no"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.telephone_no') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.office_contact_info.telephone_no"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Mobile No." vid="office_contact_info.mobile_no" rules="required|max:11" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="mobile_no"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.office_contact_info.mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Email" vid="office_contact_info.email" rules="required|email" v-slot="{ errors }">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="email"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="org_bn"
                                        type="email"
                                        v-model="application.office_contact_info.email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Fax No." vid="office_contact_info.fax_no" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="fax_no"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.fax_no') }}
                                    </template>
                                    <b-form-input
                                        v-model="application.office_contact_info.fax_no"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.factory_contact_info') }}</h5>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Telephone No." vid="factory_contact_info.telephone_no" rules="required|max:12" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="factory_contact_info.telephone_no"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.telephone_no') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.factory_contact_info.telephone_no"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Mobile No." vid="factory_contact_info.mobile_no" rules="required|max:11" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="mobile_no"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.factory_contact_info.mobile_no"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Email" vid="factory_contact_info.email" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="5"
                                    label-for="email"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.email') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        id="org_bn"
                                        type="email"
                                        v-model="application.factory_contact_info.email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Fax No." vid="factory_contact_info.fax_no" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="fax_no"
                                >
                                <template v-slot:label>
                                    {{ $t('externalUser.fax_no') }}
                                    </template>
                                    <b-form-input
                                        v-model="application.factory_contact_info.fax_no"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-3">
                        <div class="bg-dark w-50 m-auto rounded-pill">
                            <h5 class="text-white text-center"> {{ $t('externalUser.contact_person_info') }}</h5>
                        </div>
                    </b-row>
                    <b-row>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Name (En)" vid="contact_person_info.name_en" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="name_en"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.name') + ' ' + $t('globalTrans.en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="application.contact_person_info.name_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Name (Bn)" vid="contact_person_info.name_bn" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="name_bn"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.name') + ' ' + $t('globalTrans.bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="application.contact_person_info.name_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Designation (En)" vid="contact_person_info.designation_en" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="designation_en"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="application.contact_person_info.designation_en"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Designation (Bn)" vid="contact_person_info.designation_bn" rules="required" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="designation_en"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.designation') + ' ' + $t('globalTrans.bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    v-model="application.contact_person_info.designation_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col xl="6" lg="6" sm="12">
                            <ValidationProvider name="Mobile" vid="contact_person_info.mobile_no" rules="required|max:11" v-slot="{ errors }">
                                <b-form-group
                                    label-cols-sm="5"
                                    label-for="mobile_no"
                                >
                                <template v-slot:label>
                                    {{ $t('globalTrans.mobile') }} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-input
                                        v-model="application.contact_person_info.mobile"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-form>
        </ValidationObserver>
    </b-col>
</b-row>
</template>

<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { etApplicationBasicStoreApi, circularByIdApi } from '../../api/routes'
import Address from '@/components/AddressTrophy.vue'
import AdditionalAddress from '../AdditionalAddress'
import { mapGetters } from 'vuex'

export default {
    props: ['app_id', 'application', 'isShow', 'baseUrl'],
    components: { Address, AdditionalAddress },
    data () {
      return {
        fieldObj: {
            xl: 6,
            lg: 6,
            md: 6,
            sm: 12,
            labelCols: 5,
            hasPostCode: true,
            noAddress: false
        },
        address: {},
        additinal_address: {},
        valid: null,
        loading: false,
        circular: {},
        photo_input: []
      }
    },
    created () {
        if (this.$route.query.id) {
            this.circular = this.application.circular
            if (this.application.org_address) {
                this.address = {
                    country_id: this.application.org_address.country_id,
                    area_type_id: this.application.org_address.area_type_id,
                    division_id: this.application.org_address.division_id,
                    district_id: this.application.org_address.district_id,
                    upazila_id: this.application.org_address.upazila_id,
                    city_corporation_id: this.application.org_address.city_corporation_id,
                    union_id: this.application.org_address.union_id,
                    ward_id: this.application.org_address.ward_id,
                    post_code: this.application.org_address.post_code,
                    paurashava_id: this.application.org_address.paurashava_id,
                    address_details_en: this.application.org_address.address_details_en,
                    address_details_bn: this.application.org_address.address_details_bn
                }
            }
        }
        if (this.$route.query.circular_id) {
            this.getCircular()
        }
    },
    watch: {
        currentLocale: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.application.circular_name = this.$i18n.locale === 'bn' ? this.circular.title_bn : this.circular.title_en
            }
        }
    },
    computed: {
        exportSectorList () {
            return this.$store.state.ExternalUserService.cipPanel.commonObj.exportSectorList.filter(item => item.status === 1 && item.assign_for === 2).map(item => {
                if (this.currentLocale === 'bn') {
                return Object.assign({}, item, {
                    value: item.value,
                    text: item.text_bn
                })
                } else {
                return Object.assign({}, item, {
                    value: item.value,
                    text: item.text_en
                })
                }
            })
        },
        latentExporterList () {
            return this.$store.state.ExternalUserService.cipPanel.latentExporterList.map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        },
        orgTypeList: function () {
            return this.$store.state.ExternalUserService.cipPanel.orgTypeList.map(item => {
                if (this.currentLocale === 'bn') {
                    return Object.assign({}, item, {
                        text: item.text_bn
                    })
                } else {
                    return Object.assign({}, item, {
                        text: item.text_en
                    })
                }
            })
        },
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        currentLocale () {
            return this.$i18n.locale
        },
        investmentTypeList () {
            return this.$store.state.ExternalUserService.cipPanel.investmentTypeList.map(el => {
                return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
            })
        }
    },
    methods: {
        handlePhoto (e) {
            const filetype = e.target.files[0].type.split('/').pop()
            const bytesize = e.target.files[0].size
            const kbsize = bytesize / 1024
            if (filetype !== 'jpeg') {
                this.application.photo_file = ''
                this.photo_input = []
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('externalUser.signtype'),
                    color: '#ee5253'
                })
                return
            }
            if (kbsize > 100) {
                this.application.photo_file = ''
                this.photo_input = []
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('externalUser.signsize'),
                    color: '#ee5253'
                })
                return
            }
            const input = e.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.application.photo_file = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.application.photo_file = null
            }
        },
        receiveAddress (address) {
            this.address = address
        },
        additionalReceiveAddress (address) {
            this.additinal_address = address
        },
        async getCircular () {
            this.loading = true
            const param = {
                circular_id: this.application.circular_id,
                app_type: 2, // 2 means export trophy
                user_id: this.authUser.user_id
            }
            const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, circularByIdApi, param)
            if (result.success) {
                this.circular = result.data
                this.application.circular_name = this.$i18n.locale === 'bn' ? this.circular.title_bn : this.circular.title_en
                this.loading = false
            } else {
                this.$toast.error({
                    title: '!',
                    message: result.message,
                    color: '#ee5253'
                })
                this.$router.push({ path: 'circular' })
                this.loading = false
            }
        },
        async submit () {
          var check = await this.$refs.form.validate()
          if (check) {
              this.loading = true
              this.$store.dispatch('mutateCommonProperties', { loading: true })
              const loadingState = { loading: false, listReload: false }
              this.application.app_id = this.app_id
              this.application.fiscal_year_id = this.circular.fiscal_year_id
              this.application.org_address = this.address
              this.application.additional_org_address = this.additinal_address
              const result = await RestApi.postData(exportTrophyCIPServiceBaseUrl, etApplicationBasicStoreApi, this.application)
              loadingState.listReload = true
              this.$store.dispatch('mutateCommonProperties', loadingState)
              this.loading = false
              if (result.success) {
                  this.$toast.success({
                      title: 'Success',
                      message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                      color: '#D6E09B'
                  })
                  return result
              } else {
                    if (result.message) {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: result.message,
                            color: '#ee5253'
                        })
                    } else {
                        this.$toast.error({
                            title: this.$t('globalTrans.error'),
                            message: this.$t('globalTrans.form_error_msg'),
                            color: '#ee5253'
                        })
                    }
              }
          }
      }
    }
}
</script>

<style>

</style>
